@font-face {
  font-family: "GmarketB";
  src: url('./fonts/GmarketSansTTFBold.ttf');
}

@font-face {
  font-family: "GmarketM";
  src: url('./fonts/GmarketSansTTFMedium.ttf');
}

@font-face {
  font-family: "GmarketL";
  src: url('./fonts/GmarketSansTTFLight.ttf');
}

.GmarketB {
  font-family: "GmarketB";
}

.GmarketM {
  font-family: "GmarketM";
}

.GmarketL {
  font-family: "GmarketL";
}


.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 10px; /* gutter size */
  background-clip: padding-box;
}

